import {defineStore} from "pinia";
import type TrackingSessionResponse from "~/api/response/TrackingSessionResponse";
import {FetchError} from "ofetch";
import TrackingSession from "~/dto/queue/TrackingSession";
import _ from "lodash";
import type TrackingSessionData from "~/api/response/data/queue/TrackingSessionData";

export const useTrackingStore = defineStore('tracking', () => {
    const summaryStore = useSummaryStore();

    const { trackingSessions: userSessions, user } = storeToRefs(summaryStore);

    const SESSION_ID_STORAGE_KEY = 'tracking_session_id';

    const guestSessionId = ref<string | null>(localStorage?.getItem(SESSION_ID_STORAGE_KEY));
    const guestSession = ref<TrackingSession | null>(null);

    const isLookingNumber = ref<boolean>(false);
    const isLoadingGuestSession = ref<boolean>(false);
    const maxSessionsNumber = ref<number>(1);

    const trackingSessions = computed<TrackingSession[]>(() => {
        const sessions: TrackingSession[] = _.map(userSessions.value, session => session);

        if (guestSession.value) {
            sessions.push(guestSession.value);
        }

        return _.sortBy(sessions, ['item.callingOrder']);
    });

    const removeSession = (sessionId: string) => {
        if (guestSessionId.value === sessionId) {
            forgetGuestSession();
        }

        summaryStore.removeTrackingSession(sessionId);
    }

    if (process.client) {
        const updateSession = (data: TrackingSessionData) => {
            const isGuestSession = guestSession.value?.id === data.id;

            if (isGuestSession) {
                guestSession.value = new TrackingSession(data);
            }

            if (summaryStore.hasTrackingSession(data.id)) {
                summaryStore.updateTrackingSessionData(data);
            } else if (! isGuestSession) {
                unsubscribeFromSession(data.id);
            }
        };

        const { echoChannel } = useEcho();
        const trackingChannel = echoChannel("tracking");

        const subscribeToSession = (sessionId: string) => {
            trackingChannel.listen('.update.' + sessionId, function ({session: data}: {session: TrackingSessionData}) {
                updateSession(data);
            });
            trackingChannel.listen('.stop.' + sessionId, function ({id}: {id: string}) {
                removeSession(id);
            });
        };
        const unsubscribeFromSession = (sessionId: string) => {
            trackingChannel.stopListening('.update.' + sessionId);
            trackingChannel.stopListening('.stop.' + sessionId);
        };

        const trackingSessionsIds = ref<string[]>([]);

        watch(trackingSessions, (sessions: TrackingSession[]) => {
            const sessionsIds = _.map(sessions, session => session.id).sort();

            if (!_.isEqual(trackingSessionsIds.value, sessionsIds)) {
                trackingSessionsIds.value = sessionsIds;
            }
        });

        watch(trackingSessionsIds, (sessionsIds: string[], previousSessionsIds: string[]) => {
            _.difference(previousSessionsIds, sessionsIds).forEach(unsubscribeFromSession);
            _.difference(sessionsIds, previousSessionsIds).forEach(subscribeToSession);
        });
    }

    const handleTrackingSessionResponse = async (trackingSessionPromise: Promise<TrackingSessionResponse>) => {
        return trackingSessionPromise.then(({ data }: TrackingSessionResponse) => {
            return new TrackingSession(data);
        }).catch((error: FetchError) => {
            if (404 == error.statusCode) {
                return null;
            }

            throw error;
        });
    };

    const startTracking = async (registrationNumber: string) => {
        isLookingNumber.value = true;

        const promise = apiFetch<TrackingSessionResponse>(
          '/tracking',
          {
              query: {
                  registration_number: registrationNumber
              },
              timeout: 5000,
          }
        ).finally(() => {
            isLookingNumber.value = false;
        });

        return handleTrackingSessionResponse(promise).then(session => {
            if (session) {
                if (session.isGuestSession) {
                    guestSessionId.value = session.id;
                    guestSession.value = session;

                    localStorage?.setItem(SESSION_ID_STORAGE_KEY, session.id)
                } else {
                    summaryStore.addTrackingSession(session);
                }
            }

            return session;
        });
    };

    const stopTracking = (session: TrackingSession) => {
        removeSession(session.id);

        apiFetch(
          '/tracking/' + session.id,
          {
              method: "DELETE",
              timeout: 5000,
          }
        ).catch(captureException)
    };

    const loadGuestSession = async () => {
        isLoadingGuestSession.value = true;

        const promise = apiFetch<TrackingSessionResponse>(
          '/tracking/' + guestSessionId.value,
          {
              timeout: 5000,
          }
        ).finally(() => {
            isLoadingGuestSession.value = false;
        });

        return handleTrackingSessionResponse(promise).then(session => {
            if (session) {
                guestSession.value = session;
            } else {
                forgetGuestSession();
            }

            return session;
        }).catch(captureException);
    };

    const forgetGuestSession = () => {
        guestSessionId.value = null;
        guestSession.value = null;

        localStorage?.removeItem(SESSION_ID_STORAGE_KEY);
    };

    if (guestSessionId.value) {
        loadGuestSession().catch(forgetGuestSession)
    }

    const increaseMaxTrackingSessionNumber = () => {
        if (user.value && maxSessionsNumber.value < 5) {
            maxSessionsNumber.value = trackingSessions.value.length + 1;
        }
    };

    return {
        isLookingNumber,
        isLoadingGuestTrackingSession: isLoadingGuestSession,
        maxTrackingSessionsNumber: maxSessionsNumber,
        trackingSessions,
        startTracking,
        stopTracking,
        increaseMaxTrackingSessionNumber,
    };
});
